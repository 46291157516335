import React from "react"
import { Link } from "gatsby"
import Head from "../components/head"

const AboutPage = () => {
  return (
    <section id="about" className="about__container">
      <Head />
      <div className="about">
        <h1 className="about__title">About</h1>
        <p className="about__me">
          Hi, my name is Vincent Tern. I currently work for threeTheorem in
          Sacramento, California as a Web Developer. I also do some freelance
          work helping local businesses built their website. On my free time I
          enjoy playing basketball and working out.
        </p>

        <p className="about__me">
          My coding journey started with fiddling around with HTML5 and CSS3,
          after a few months I started learning Javascript. A few months after I
          started building Frontend projects with Javascript, HTML5, CSS3, Sass.
          To expand my knowledge I picked up a Frontend framework and decided to
          go with React.js then when I comfortable, I dive into Redux and
          Node.js. Shortly, after I wanted to learn something that will impress
          clients that's when I discovered Gatsby which is all about
          performance.
        </p>

        <p className="about__me">
          I love learning and helping people build out their website. It makes
          me happy and satisfied bringing their ideas to life.{" "}
        </p>

        <p className="about__me">
          What about you? Need a developer? or curious to know more? If so,
          let's connect.
        </p>

        <div className="contact__me--box">
          <i className="fas fa-angle-double-down"></i>

          <Link className="connectInTouch btn" to="/#contact">
            Click Here
          </Link>
        </div>
      </div>
    </section>
  )
}

export default AboutPage
